@tailwind base;

@tailwind components;

@tailwind utilities;

/* styles.css */
header,
footer {
  min-height: 5vh;
}
main {
  /* min-height: 83vh; */
}
